<template>
  <WeContainer header="1" v-bind:actions="actions">
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:showOnLoad="checkFilterStorage()"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Order Status -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="order_statuses"
                label="Sipariş Durumu"
                placeholder="Sipariş Durumu"
                v-bind:option-prop="order.orderTypes"
                v-bind:multiple="true"
                v-bind:close-on-select="false"
                v-model="filter.order_statuses"
              />
            </div>
            <!-- Order Status -->

            <!-- Payment Type -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="payment_type"
                label="Ödeme Yöntemi"
                placeholder="Ödeme Yöntemi"
                v-bind:option-prop="order.paymentTypes"
                v-model="filter.payment_type"
              />
            </div>
            <!-- Payment Type -->

            <!-- Shipping Companies -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="shipping_company"
                label="Kargo Firması"
                placeholder="Kargo Firması"
                v-bind:option-prop="getCargoCompanyNames"
                v-model="filter.shipping_company"
              />
            </div>
            <!-- Shipping Companies -->
            <!-- Marketplace -->
            <div class="d-block mb-3" v-if="marketplaceConfig">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="marketplace_filter"
                label="Pazaryeri"
                placeholder="Pazaryeri"
                v-bind:option-prop="detailMarketplaceList"
                v-bind:reduce="true"
                select-label="title"
                v-model="filter.marketplace"
              />
            </div>
            <!-- Marketplace -->

            <!-- ERP Status -->
            <div class="d-block mb-3" v-if="erpConfig">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="erp_filter"
                label="ERP"
                placeholder="ERP"
                v-bind:option-prop="erpOptions"
                v-bind:reduce="true"
                v-model="filter.erp_status"
              />
            </div>
            <!-- ERP Status -->

            <!-- Order No -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="order_no"
                label="Sipariş No"
                placeholder="Sipariş No"
                v-model="filter.order_no"
              />
            </div>
            <!-- Order No -->

            <!-- Price -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label for="price" class="custom-label">Sipariş Tutarı</label>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row align-items-center">
                    <div class="col">
                      <WePriceInput
                        v-bind:small-size="true"
                        class="mb-0"
                        placeholder="0,00"
                        v-model="filter.start_price"
                        ref="startPrice"
                      />
                    </div>
                    <span>-</span>
                    <div class="col">
                      <WePriceInput
                        v-bind:small-size="true"
                        class="mb-0"
                        placeholder="0,00"
                        v-model="filter.end_price"
                        ref="endPrice"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Price -->

            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Sipariş Tarihi"
                placeholder="Sipariş Tarihi"
                v-bind:date-range="true"
                v-model="filter.date"
              />
            </div>
            <!-- Created At -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Ref No -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="ref_no"
                label="Referans No"
                placeholder="Referans No"
                v-model="filter.ref_no"
              />
            </div>
            <!-- Ref No -->

            <!-- Shipping Customer Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="shipment_customer_name"
                label="Teslimat - Ad Soyad"
                placeholder="Teslimat - Ad Soyad"
                v-model="filter.shipment_customer_name"
              />
            </div>
            <!-- Shipping Customer Name -->

            <!-- Shipping City -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                v-bind:clearable="true"
                name="shipment_city"
                label="Teslimat - Şehir"
                placeholder="Teslimat - Şehir"
                select-label="name"
                v-bind:option-prop="cityList"
                v-model="filter.shipment_city"
              />
            </div>
            <!-- Shipping City -->

            <!-- Invoice Name -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="invoice_customer_name"
                label="Fatura - Ad Soyad"
                placeholder="Fatura - Ad Soyad"
                v-model="filter.invoice_customer_name"
              />
            </div>
            <!-- Invoice Name -->

            <!-- Shipping City -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="vSelect"
                name="invoice_city"
                label="Fatura - Şehir"
                placeholder="Fatura - Şehir"
                v-bind:clearable="true"
                select-label="name"
                v-bind:option-prop="cityList"
                v-model="filter.invoice_city"
              />
            </div>
            <!-- Shipping City -->

            <!-- Customer Email -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                name="customer_email"
                label="E-Posta Adresi"
                placeholder="E-Posta Adresi"
                v-model="filter.customer_email"
              />
            </div>
            <!-- Customer Email -->

            <!-- Customer GSM -->
            <div class="d-block mb-3">
              <WeRowInput
                size="sm"
                form="phone"
                name="customer_gsm"
                label="Cep Telefonu"
                placeholder="Cep Telefonu"
                v-model="filter.customer_gsm"
              />
            </div>
            <!-- Customer GSM -->

            <!-- Product Info -->
            <div class="d-block mb-3">
              <div class="form-row align-items-center">
                <div class="col-12 col-lg-3">
                  <label class="custom-label">Ürün Bilgisi</label>
                </div>
                <div class="col-12 col-lg-6">
                  <WeInput
                    class="mb-0"
                    small-size="1"
                    autocomplete="off"
                    v-model="filter.product_search_query"
                  >
                    <template v-slot:prepend>
                      <select
                        class="
                          custom-select custom-select-sm
                          border-right-radius-0
                        "
                        v-model="filter.product_search_type"
                      >
                        <option value="null">Yok</option>
                        <option value="sku">Stok Kodu</option>
                        <option value="name">Ürün Adı</option>
                      </select>
                    </template>
                  </WeInput>
                </div>
              </div>
            </div>
            <!-- Product Info -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>

    <!-- Tüm Siparişleri Göster -->
    <WeSelect
      style="width: 250px"
      class="mb-3 mb-lg-0 float-lg-right"
      v-bind:options="orderFilter.options"
      v-bind:value="orderFilter.value"
      v-on:input="onChangeOrderFilter"
    />
    <!-- ./Tüm Siparişleri Göster -->
    <WeTable
      v-bind:index="false"
      v-bind:all-select="true"
      v-bind:selectable="true"
      v-bind:select-limit="25"
      v-bind:data="orderList"
      v-bind:allowSortIndex="false"
      v-bind:columns="getColumns"
      v-bind:componentName="componentName"
      v-bind:actions="tableActions"
      v-bind:ajax="true"
      v-bind:loading="loading"
      v-bind:table-result="tableResult"
      v-bind:responsive="false"
      v-bind:exportables="getExportables"
      v-on:on-export="onExport"
      v-on:on-action="onAction"
      v-on:on-checked="onChecked"
      v-on:on-select="onSelect"
      v-on:make-request="updateOrderList"
      ref="orderTable"
    ></WeTable>
    <!-- <div v-else class="position-relative" style="min-height: 200px;">
      <WeLoading />
    </div> -->
    <OrderDetail
      v-show="modal.detail"
      v-bind:order="orderData"
      v-on:close="closeOrderDetail"
      v-on:on-update-cargo="updateCargoBarcodeNumber"
      v-on:on-update-info="updateCargoInfo"
      v-on:on-get-tracking-number="getCargoTrackingNumber"
      v-bind:erp-config="erpConfig"
      ref="orderDetailRef"
    />

    <!-- Kargoya Verildi -->
    <WeModal
      v-if="modal.cargo && orderData"
      v-on:close="closeCargoModal"
      icon="fas fa-info-circle"
      modal-width="50"
      v-bind:title="`${orderData.order_no} - Kargo Bilgileri`"
    >
      <template slot="body">
        <div class="d-block">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="d-block mb-3">
                <WeInput
                  class="mb-3 mb-lg-0"
                  v-model="cargoDetail.package_quantity"
                  v-bind:filter-number="true"
                  label="Koli Miktarı"
                  name="package-quantity"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="d-block mb-3">
                <WeInput
                  class="mb-3 mb-lg-0"
                  v-model="cargoDetail.package_desi"
                  v-bind:filter-number="true"
                  label="Desi"
                  name="package-desi"
                />
              </div>
            </div>
          </div>

          <div class="d-block mb-3">
            <label for="cargo-id" class="custom-label">Kargo Firması</label>
            <v-select
              id="cargo-id"
              v-bind:value="
                cargoDetail.cargo && cargoDetail.cargo.cargo_company
                  ? cargoDetail.cargo.cargo_company.name
                  : cargoDetail.cargo
              "
              v-on:input="cargoDetail.cargo = $event"
              v-bind:options="getCargoCompanyNames"
              v-bind:clearable="false"
              v-bind:selectable="(option) => option.selectable"
              label="name"
            >
              <template #option="{ name, selectable }">
                {{ name }}
                <i
                  v-if="!selectable"
                  class="fas fa-info-circle ml-2"
                  v-tooltip="'Teslimat Bölgesi Dışı'"
                ></i>
              </template>
            </v-select>
          </div>
          <div
            v-if="
              !barcodeSubmit &&
              cargoDetail.cargo &&
              cargoDetail.cargo.is_insurance
            "
            class="d-block"
          >
            <WeInput
              class="mb-0"
              label="Ürün Tipi"
              v-model="cargoDetail.product_type"
            />
          </div>
          <div class="d-block mb-3">
            <span
              class="btn btn-primary"
              v-if="
                !barcodeSubmit &&
                cargoDetail.cargo &&
                cargoDetail.cargo.is_integration &&
                !cargoDetail.barcode_url
              "
              v-on:click="saveBarcode"
              >Kargo Kaydet</span
            >
            <span v-else-if="barcodeSubmit" class="btn btn-primary"
              ><i class="fas fa-spinner fa-spin"></i
            ></span>

            <WeInput
              v-else-if="!barcodeSubmit"
              class="mb-0"
              v-model="cargoDetail.cargo_barcode_number"
              v-bind:disabled="
                cargoDetail.cargo &&
                cargoDetail.cargo.is_integration &&
                cargoDetail.cargo_barcode_number
              "
              label="Kargo Kayıt Numarası"
              name="cargo-barcode-number"
            />
          </div>
          <template
            v-if="cargoDetail.cargo"
            class="d-block"
          >
            <div class="row align-items-center">
              <div class="col">
                <span
                  v-on:click="updateCargoBarcodeNumber"
                  class="btn btn-sm btn-success"
                  ><i
                    :class="
                      isUpdateBarcodeNumber
                        ? 'fas fa-spinner fa-spin'
                        : 'fas fa-sync mr-1'
                    "
                  ></i>
                  Güncelle</span
                >
              </div>
              <div class="col-auto">
                <!-- Barkod Yazdır -->
                <PrintBarcodeButton
                  v-bind:order-id="orderData.id"
                  v-bind:cargo-code="getCargoCompanyCode"
                  v-bind:barcode-url="cargoDetail.barcode_url"
                  v-bind:package-quantity="cargoDetail.package_quantity"
                />
                <!-- ./Barkod Yazdır -->
              </div>
            </div>
          </template>
        </div>
        <div
          class="text-left mt-3"
          v-if="
            !cargoDetail.cargo ||
            (cargoDetail.cargo && !cargoDetail.cargo.is_integration)
          "
        >
          <WeSubmitButton v-on:submit="updateCargoBarcodeNumber" />
        </div>
      </template>
    </WeModal>
    <!-- ./Kargoya Verildi -->

    <!-- Toplu Yazdır -->
    <BulkOrderPrint
      v-bind:data="printOrders"
      v-bind:site-logo="siteLogo"
      ref="bulkPrint"
    />
    <!-- ./Toplu Yazdır -->

    <!-- Export -->
    <WeListExport
      v-if="showListExport"
      field-key="order"
      v-bind:required-keys="['created_at', 'order_no', 'name', 'price']"
      v-bind:fixed-selected="exportSelection"
      v-on:close="onCloseExportList"
    />
    <!-- ./Export -->
  </WeContainer>
</template>

<script>
const OrderDetail = () => import("./views/OrderDetail/Index");
const BulkOrderPrint = () => import("./views/BulkOrderPrint/Index");
const PrintBarcodeButton = () =>
  import("./components/PrintBarcodeButton/Index.vue");

import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "List",
  components: {
    OrderDetail,
    BulkOrderPrint,
    PrintBarcodeButton,
  },
  data() {
    return {
      componentName: "orderlist",
      showListExport: false,
      fixedKeys: ["created_at", "order_no", "name", "price"],
      orderData: null,
      loading: false,
      barcodeSubmit: false,
      isUpdateBarcodeNumber: false,
      selectedOrders: [],
      printOrders: [],
      printBtn: null,
      printCounter: 0,
      orderFilter: {
        value: {
          id: "successful",
          name: "Başarılı Siparişler",
        },
        options: [
          {
            id: "all",
            name: "Tüm Siparişler",
          },
          {
            id: "cancel_returns",
            name: "İptal & İadeler",
          },
          {
            id: "successful",
            name: "Başarılı Siparişler",
          },
        ],
      },
      actions: [
        {
          text: "Yeni Sipariş",
          path: "orders/new",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-eye",
          class: "btn-sm btn-outline-indigo",
          action: "show-detail",
          tooltip: "Sipariş Detayı",
        },
        {
          icon: "fas fa-print",
          class: "btn-sm btn-outline-secondary ml-2",
          action: "print-out",
          tooltip: "Yazdır",
        },
      ],
      columns: [
        {
          name: "note_count",
          type: "table_badge",
          icon: "fas fa-tags",
          sortable: false,
          iconTooltip: "Sipariş Notu",
        },
        { name: "date", th: "Sipariş Tarihi", type: "datetime", width: "15%" },
        {
          name: "source",
          th: "Platform",
          type: "image_url",
          image_width: 60,
        },
        { name: "order_no", th: "Sipariş No", type: "string", width: "10%" },
        {
          name: "name",
          th: "Müşteri Adı",
          type: "string",
          width: "15%",
          maxWidth: "170",
        },
        {
          name: "payment_type",
          th: "Ödeme",
          type: "string",
          width: "5%",
        },
        {
          name: "cargo_company_name",
          th: "Kargo",
          type: "string",
          maxWidth: "100",
          textLength: "15",
        },
        { name: "shipment_city", th: "Şehir", type: "string", width: "10%" },
        { name: "price", th: "Tutar", type: "currency", width: "10%" },
      ],
      copyFilter: {},
      filter: {
        order_statuses: null, // Sipariş Durumu
        payment_status: null, // Ödeme Durumu
        payment_type: null, // Ödeme Türü
        shipping_company: null, // Kargo Firması
        order_no: null, // Sipariş No
        ref_no: null, // Referans No
        marketplace: null,
        erp_status: null,

        // Müşteri
        shipment_customer_name: null,
        invoice_customer_name: null,
        customer_email: null,
        customer_member_group: null,
        customer_gsm: null,

        // Sipariş Tutarı
        start_price: helper.setPrice(0),
        end_price: helper.setPrice(0),
        price_currency: null,
        product_search_type: null,
        product_search_query: null,

        // Tarih
        date: {
          start: null,
          end: null,
        },
      },
      modal: {
        detail: false,
        cargo: false,
      },
      cargoDetail: {
        order_id: null,
        package_quantity: 1,
        package_desi: 1,
        cargo_tracking_number: null,
        cargo_barcode_number: null,
        cargo: null,
        product_type: null,
      },
      tableResult: {
        item_count: 1,
        current_page: 1,
        total_page: 1,
        total_items: 1,
      },
      erpOptions: [
        {
          id: "1",
          name: "Aktarılan",
        },
        {
          id: "0",
          name: "Aktarılamayan",
        },
        {
          id: "2",
          name: "Gönderilmeyen",
        },
      ],
    };
  },
  methods: {
    ...mapActions("order", [
      "getById",
      "getList",
      "delete",
      "getOrderTypes",
      "getPaymentTypes",
      "updateOrderStatus",
      "setCargoOrder",
      "getTrackingNumber",
      "updateCargoInformation",
      "sendERP",
      "sendInvoice",
      "printInvoice",
    ]),
    ...mapActions({
      getOrderDetail: "orderDetailModal/getById",
      exportData: "tableExport/exportData",
    }),
    ...mapMutations({
      clearOrderDetail: "orderDetailModal/clearOrder",
    }),
    ...mapActions("shared", ["getCargoFirm", "getAvailableCargoFirm"]),
    ...mapActions("address", ["getCities"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapMutations("order", ["updateList"]),

    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      } else if (data.key == "show-detail") {
        this.showDetail(data.row.id);
      } else if (data.key == "print-out") {
        this.getOrderById(data.row.id, this.onPrintOut);
      } else if (data.key == "barcode-print") {
        window.open(data.row.cargo_barcode_url, "_blank").focus();
      } else if (data.key == "send-erp") {
        const index = this.orderList.findIndex(
          (item) => item.id === data.row.id
        );
        if (index >= 0) {
          let item = this.orderList[index];
          item.erp_status = "-1";
          this.onSendERP(data.row.id, item);
        }
      } else if (data.key == "send-invoice") {
        const index = this.orderList.findIndex(
          (item) => item.id === data.row.id
        );
        if (index >= 0) {
          let item = this.orderList[index];
          item.invoice_status = "-1";
          this.onSendInvoice(data.row.id, item);
        }
      } else if (data.key == "print-invoice") {
        const index = this.orderList.findIndex(
          (item) => item.id === data.row.id
        );
        if (index >= 0) {
          let item = this.orderList[index];
          item.invoice_status = "-1";
          this.onPrintInvoice(data.row.id, item);
        }
      }
    },
    onEdit(row) {
      this.$router.replace("orders/detail/" + row.id);
    },
    onDelete(row) {
      let message = "Siparişi silmek istediğinize emin misiniz ?";

      this.$swal({
        text: message,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.delete({
            id: row.id,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$swal({
                  title: "Sipariş Başarıyla Silindi",
                  icon: "success",
                  confirmButtonText: "Tamam",
                });
              } else {
                this.$swal({
                  title: "Sipariş Silinemedi",
                  text: result.data.message,
                  icon: "error",
                  confirmButtonText: "Tamam",
                });
              }
            },
          });
        }
      });
    },
    onSelect(data) {
      const checkPermission = permission.check("order", "u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        const selectedValue = data.value;
        const oldValue = data.old;
        const statusIndex = this.orderStatusIndex(selectedValue.id); // Kargo Hazırlanıyor
        if (statusIndex == 4) {
          this.changeToCargoStatus(data);
        } else {
          this.changeOrderStatus(data);
        }
      }
    },
    onChecked(data) {
      if (data && data.length) {
        this.selectedOrders = helper.arrayPluck(data, "id");
      } else {
        this.selectedOrders = [];
      }
    },
    onExport(data) {
      switch (data.name) {
        case "bulk-print":
          if (!data.loading) {
            this.printOrders = [];
            this.printCounter = 0;
            this.startBulkPrint(data);
          }
          break;
        case "excel":
          this.exportDetail = data;
          this.showListExport = true;
          break;
      }
    },
    onCloseExportList(data) {
      if (data && data.download) {
        if (this.order.list && this.order.list.length) {
          if (!data.selected.length) {
            this.$toast.error("Lütfen Sütun seçimi yapın");
          } else {
            if (
              this.exportDetail.name !== "print" &&
              !this.exportDetail.loading
            ) {
              let fileName = "Sipariş Listesi";

              if (
                this.filter.date &&
                this.filter.date.start &&
                this.filter.date.end
              ) {
                let startDate = new Date(
                  this.filter.date.start
                ).toLocaleDateString("tr-TR");
                let endDate = new Date(this.filter.date.end).toLocaleDateString(
                  "tr-TR"
                );

                fileName = startDate + " - " + endDate + " - " + fileName;
              }

              this.exportData({
                route: "export-list/orders",
                exportable: this.exportDetail,
                fileName: fileName,
                filter: {
                  ...this.getFilter(),
                  type: this.exportDetail.name,
                },
                fields: data.selected,
              });
              this.showListExport = false;
            }
          }
        } else {
          this.$swal({
            title: "Dışarı aktarılacak veri mevcut değil",
            icon: "warning",
            confirmButtonText: "Tamam",
          });
        }
      } else {
        this.showListExport = false;
      }
    },
    startBulkPrint(btn) {
      btn.loading = true;
      btn.title = "Hazırlanıyor...";
      this.printBtn = btn;

      if (this.selectedOrders && this.selectedOrders.length) {
        this.selectedOrders.forEach((order) => {
          this.printCounter++;

          this.getById({
            id: order,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                const item = result.data.item;
                this.setOrderDetail(item);
                this.printOrders.push(this.orderData);
              }
            },
            onFinish: () => {
              this.printCounter--;
            },
          });
        });
      }
    },
    closeOrderDetail() {
      this.modal.detail = false;
      this.closeCargoModal();
    },
    changeToCargoStatus(data) {
      this.$swal({
        title: "İşlemi Onaylıyor musunuz ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Kaydet",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const orderId = data.row.id;

          this.modal.cargo = true;
          this.cargoDetail.order_id = orderId;
          this.cargoDetail.data = data; // set data (row id etc.) to cargo detail
          this.getOrderById(orderId, this.submitCargoDetail);
        } else {
          const foundedOrder = this.order.list.find(
            (order) => order.id === data.row.id
          );
          if (foundedOrder) {
            foundedOrder.status = data.old;
          }
        }
      });
    },
    getAvailableCargoCompanies(orderId) {
      let result = [];

      this.getAvailableCargoFirm({
        order_id: orderId,
        onSuccess: (res) => {
          const items = res?.data?.items;

          if (items && items.length) {
            items.forEach((cargo) => {
              result.push(cargo.id);
            });
          }
        },
      });

      return result;
    },
    changeOrderStatus(data) {
      const request = {
        order: data.row.id,
        status: data.value.id,
      };
      this.updateOrderStatus({
        request,
        onSuccess: (result) => {
          if (result && result.data && result.data.status) {
            this.$toast.info("Sipariş Durumu Güncellendi");
          }
        },
      });
    },
    showDetail(id) {
      this.modal.detail = true;
      this.orderData = null;
      this.getOrderById(id);
    },
    getOrderById(id, onFinish = null) {
      this.getById({
        id: id,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.setOrderDetail(result.data.item);
          }
        },
        onFinish: () => {
          if (onFinish) onFinish();
        },
      });
    },
    setOrderDetail(item) {
      const data = {};
      data.id = item.id;
      data.order_no = item.order_no;
      data.order_type = item.order_type ? item.order_type.name : null;
      // data.order_type_index = this.orderStatusIndex(item.order_type_id);
      data.payment_type = item.payment_type ? item.payment_type.name : null;
      data.ref_no = item.ref_no;
      data.bank_company = item.bank_company;
      data.user_ip = item.user_ip ? item.user_ip : null;
      data.user_agent = item.user_agent ? item.user_agent : null;
      data.bank_result = {
        data: item.bank_result !== null ? JSON.parse(item.bank_result) : null,
        bank: item.payment_bank ? item.payment_bank.name : null,
      };
      data.selected_bank = item?.selected_bank;
      data.barcodeImage =
        item.order_type.name != "İptal Edildi"
          ? this.createBarcodeUrl(item.order_no_barcode)
          : null;
      data.notes = item.notes || [];

      if (item.delivery_date) {
        data.attachment = {
          delivery_date: item.delivery_date,
          delivery_start_time: item.delivery_start_time,
          delivery_due_time: item.delivery_due_time,
        };
      } else {
        const prodWithDelivery = item.products.find(
          (prod) => prod.delivery_date
        );
        if (prodWithDelivery) {
          data.attachment = helper.clone(prodWithDelivery);
        }
      }

      // Invoice Tab
      data.invoice = {
        country: item.invoice_country ? item.invoice_country.name : null,
        city: item.invoice_city ? item.invoice_city.name : null,
        district: item.invoice_district ? item.invoice_district.name : null,
        neighborhood: item.invoice_neighborhood
          ? item.invoice_neighborhood.name
          : null,
        postal_code: item.invoice_postal_code,
        address: item.invoice_address,
      };

      // Shipment Tab
      data.shipment = {
        country: item.shipment_country ? item.shipment_country.name : null,
        city: item.shipment_city ? item.shipment_city.name : null,
        district: item.shipment_district ? item.shipment_district.name : null,
        neighborhood: item.shipment_neighborhood
          ? item.shipment_neighborhood.name
          : null,
        postal_code: item.shipment_postal_code,
        address: item.shipment_address,
      };

      // Customer
      data.customer = {
        id: 1,
        type: 0,
        shipment: {
          name: item.shipment_first_name
            ? item.shipment_first_name + " " + item.shipment_last_name
            : "",
          first_name: item.shipment_first_name,
          last_name: item.shipment_last_name,
          email: item.shipment_email,
          phone:
            item.shipment_phone && item.shipment_phone
              ? item.shipment_phone
              : null,
          gsm:
            item.shipment_gsm && item.shipment_gsm.length
              ? item.shipment_gsm
              : null,
        },
        invoice: {
          name: item.invoice_first_name
            ? item.invoice_first_name + " " + item.invoice_last_name
            : "",
          first_name: item.invoice_first_name,
          last_name: item.invoice_last_name,
          email: item.invoice_email,
          phone:
            item.invoice_phone && item.invoice_phone
              ? item.invoice_phone
              : null,
          gsm:
            item.invoice_gsm && item.invoice_gsm.length
              ? item.invoice_gsm
              : null,
          tcn:
            item.invoice_tcn && item.invoice_tcn.length
              ? item.invoice_tcn
              : null,
          vkn:
            item.invoice_vkn && item.invoice_vkn.length
              ? item.invoice_vkn
              : null,
          tax_office: item.invoice_tax_office,
          company: item.invoice_company,
        },
      };

      // Seçilebilir Kargo Firmaları
      data.available_cargo_ids = this.getAvailableCargoCompanies(item.id);

      // Cargo
      data.cargo = item.cargo;
      data.cargo_company_code = item.cargo_company_code;
      this.cargoDetail.cargo = data.cargo;
      this.cargoDetail.cargoStatus = item.cargoStatus;
      this.cargoDetail.cargo_tracking_number = item.cargo_tracking_number;
      this.cargoDetail.cargo_barcode_number = item.cargo_barcode_number;
      this.cargoDetail.barcode_url = item.cargo_barcode_url;
      data.cargoDetail = this.cargoDetail;

      data.erp = {
        status: item.erp_status,
        message: item.erp_message,
      };

      // Detail Info
      data.order_note = item.order_note;
      data.gift_wrap = parseInt(item.gift_wrap);
      data.gift_note = item.gift_note;
      data.coupon_code = item.coupon_code || null;
      data.campaign = {
        code: null,
        price: null,
      };

      // Ürünlerin kopyası
      data.products = item.products.map((product) => {
        const noteTextCheck = product.note_text && product.note_text.length;
        const extraNoteCheck = product.extra_note && product.extra_note.length;
        const orderImageCheck = product.order_image && product.order_image.path;

        if (item?.currency_code.code) {
          product.currency_code = item.currency_code;
        }

        // Sipariş -> Ürün Notu
        if (noteTextCheck || extraNoteCheck || orderImageCheck) {
          product.name += "<br />"; // Product Name

          // Note Text
          if (noteTextCheck) {
            product.name += `<div class="d-block font-13" 
                                  style="max-width: 350px;overflow: hidden;word-break: break-all;white-space: initial;">
                                    <b>Not:</b> ${product.note_text}
                              </div>`;
          }

          // Extra Note
          if (extraNoteCheck) {
            const noteIsURL = helper.isURL(product.extra_note);
            let extraNoteContent = product.extra_note;

            if (noteIsURL) {
              extraNoteContent = `<a href="${product.extra_note}" target="_blank">Göster<i class="fas fa-external-link-alt ml-2"></i></a>`;
            }

            product.name += `<div class="d-block font-13"><b>Ekstra Not:</b> ${extraNoteContent}</div>`;
          }

          // Note Paper
          if (product.note_paper && product.note_paper.length) {
            product.name += `<div class="d-block font-13"><b>Not Kağıdı:</b> ${product.note_paper}</div>`;
          }

          // Order Product Image
          if (orderImageCheck) {
            product.name += `<div class="d-block font-13"><b>Sipariş Görseli:</b> <a href="${product.order_image.path}" target="_blank">Göster<i class="fas fa-external-link-alt ml-2"></i></a></div>`;
          }
        }

        return product;
      });

      const currencyCode = item.currency_code ? item.currency_code.code : "TRY";
      // Kupon İndirimi
      if (item.coupon_discount && parseFloat(item.coupon_discount)) {
        data.coupon_discount = localization.getMoneyFormat(
          currencyCode,
          item.coupon_discount
        );
      }

      // Kampanya İndirimi
      if (item.campaign_discount && parseFloat(item.campaign_discount)) {
        data.campaign_discount = localization.getMoneyFormat(
          currencyCode,
          item.campaign_discount
        );
      }

      // Genel İndirim (Havale vs.)
      if (
        item.bank_transfer_discount &&
        parseFloat(item.bank_transfer_discount)
      ) {
        data.bank_transfer_discount = localization.getMoneyFormat(
          currencyCode,
          item.bank_transfer_discount || 0
        );
      }

      // Taksit
      // 0 = Tek Çekim
      data.installment = item.installment;
      data.installment_amount = item.installment_amount;

      // KDV
      data.total_tax = localization.getMoneyFormat(
        currencyCode,
        item.total_tax
      );

      // Kargo
      data.total_cargo = localization.getMoneyFormat(
        currencyCode,
        item.total_cargo
      );

      // Ara Toplam
      data.sub_total = localization.getMoneyFormat(
        currencyCode,
        parseFloat(item.total_price) + parseFloat(item.total_tax)
      );

      // Ürün toplam fiyatı
      data.total_price = localization.getMoneyFormat(
        currencyCode,
        item.total_price
      );

      // Genel Toplam
      data.total_amount = localization.getMoneyFormat(
        currencyCode,
        item.total_amount
      );

      this.orderData = data;
    },
    orderStatusIndex(id) {
      return helper.arrayFind(this.order.orderTypes, "id", id);
    },
    // getOrderTypeName(id) {
    //   const index = this.orderStatusIndex(id);

    //   if (index !== -1) {
    //     return this.order.orderTypes[index].name;
    //   }
    // },
    // getPaymentTypeName(id) {
    //   const index = helper.arrayFind(this.order.paymentTypes, "id", id);

    //   if (index !== -1) {
    //     return this.order.paymentTypes[index].name;
    //   }
    // },
    onSearch() {
      this.copyFilter = helper.clone(this.filter);
      this.$refs.orderTable.currentPage = 1;
      helper.addCacheCurrentFilter(this.copyFilter, this.componentName);
      this.updateOrderList(null, true);
    },
    checkFilterStorage() {
      return helper.checkFilterStorage(this.componentName);
    },
    getFilter() {
      let copy = this.copyFilter;

      copy.order_status_filter =
        this.orderFilter.value && this.orderFilter.value.id;

      if (!Object.keys(copy).length) {
        return;
      }

      if (copy.start_price && copy.start_price.hasOwnProperty("unmask")) {
        copy.start_price = copy.start_price.unmask || null;
      }
      if (copy.end_price && copy.end_price.hasOwnProperty("unmask")) {
        copy.end_price = copy.end_price.unmask || null;
      }
      copy.date = [];

      if (this.filter.date && this.filter.date.start && this.filter.date.end) {
        Object.entries(this.filter.date).forEach(([key, value]) => {
          if (value) {
            copy.date.push(value);
          }
        });
      }

      // ERP Config
      if (!this.erpConfig) this.filter.erp = null;

      // Marketplace Config
      if (!this.marketplaceConfig) this.filter.marketplace = null;

      // Search Product BY
      let type = this.filter.product_search_type;
      let productQuery = this.filter.product_search_query;
      if (type && type !== "null") {
        if (type == "name") {
          copy.product_name = productQuery;
        } else {
          copy.product_sku = productQuery;
        }
      }

      delete copy.product_search_type;
      delete copy.product_search_query;

      return copy;
    },
    onFilterClear() {
      this.copyFilter = {};
      helper.clearItem(this.filter);
      helper.removeCacheCurrentFilter(this.componentName);
      this.filter.start_price = helper.setPrice(0);
      this.filter.end_price = helper.setPrice(0);

      this.updateOrderList();
    },

    updateOrderList(requestData = null, detailedSearch = false) {
      requestData = requestData || {
        page: 1,
        limit: 25,
      };

      /* Filter Cache Load */
      var localFilter = localStorage.getItem(
        `last_${this.componentName}_filter`
      );
      this.filter = helper.loadLocalFilter(this.filter, localFilter);
      this.copyFilter = helper.clone(this.filter);

      // Detaylı arama yapılırsa Tüm Siparişlerde arama yap
      let filter = this.getFilter();
      if (detailedSearch) {
        delete filter.order_status_filter;
      }

      this.loading = true;

      this.getList({
        filter: filter,
        table: requestData,
        onSuccess: (result) => {
          helper.copy(result, this.tableResult, ["items", "status"]);
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
    closeCargoModal() {
      this.modal.cargo = false;
      this.cargoDetail = {
        order_id: null,
        package_quantity: 1,
        package_desi: 1,
        cargo_tracking_number: null,
        cargo: null,
        barcode_url: null,
      };
    },
    submitCargoDetail() {
      this.changeOrderStatus(this.cargoDetail.data);
    },
    onPrintOut() {
      this.$refs.orderDetailRef.$refs.printButton.click();
    },
    updateCargoBarcodeNumber() {
      if (this.orderData) {
        this.isUpdateBarcodeNumber = true;
        // Entegrasyon bilgisi | boolean
        const integration =
          this.cargoDetail.cargo && this.cargoDetail.cargo.is_integration;

        // Koli adet, kargo firması
        const requestData = {
          package_quantity: this.cargoDetail.package_quantity || 1,
          cargo_id: this.cargoDetail.cargo ? this.cargoDetail.cargo.id : null,
          package_desi: this.cargoDetail.package_desi || 1,
        };

        requestData.cargo_tracking_number =
          this.cargoDetail.cargo_tracking_number;

        requestData.cargo_barcode_number =
          this.cargoDetail.cargo_barcode_number;

        if (this.cargoDetail.cargo && this.cargoDetail.cargo.is_insurance) {
          requestData.product_type = this.cargoDetail.product_type;
        }
        if (
          this.cargoDetail.cargo &&
          this.cargoDetail.cargo.id &&
          (this.cargoDetail.cargo_barcode_number ||
            this.cargoDetail.cargo_barcode_number !== "[]")
        ) {
          // Kargo bilgilerini gönder
          this.setCargoOrder({
            id: this.orderData.id,
            form: requestData,
            onSuccess: (result) => {
              if (result.data && result.data.status !== "error") {
                if (integration) {
                  this.cargoDetail.barcode_url = result.data.barcodeUrl;
                  this.cargoDetail.cargo_tracking_number =
                    result.data.trackingNumber;
                  this.cargoDetail.cargo_barcode_number =
                    result.data.barcodeNumber;
                }
                this.$toast.success("Kargo kaydetme işlemi başarılı!");
              } else {
                if (integration) {
                  this.cargoErrorHandler(
                    result.data,
                    this.cargoDetail.cargo?.cargo_company
                  );
                }
              }
            },
            onError: () => {
              this.$toast.error("Sunucu Hatası");
            },
            onFinish: () => {
              if (!integration) {
                this.closeCargoModal();
              } else {
                this.barcodeSubmit = false;
              }
              this.isUpdateBarcodeNumber = false;
              this.$refs.orderDetailRef.onReturnSubmit("update-cargo");
            },
          });
        }
      }
    },
    getCargoTrackingNumber() {
      if (this.orderData) {
        // Entegrasyon bilgisi | boolean
        const integration =
          this.cargoDetail.cargo && this.cargoDetail.cargo.is_integration;

        const requestData = {
          cargo_barcode_number: this.cargoDetail.cargo_barcode_number,
          cargo_id: this.cargoDetail.cargo ? this.cargoDetail.cargo.id : null,
        };

        this.getTrackingNumber({
          id: this.orderData.id,
          form: requestData,
          onSuccess: (result) => {
            if (result.data && result.data.status !== "error") {
              if (integration) {
                this.cargoDetail.cargo_tracking_number =
                  result.data.trackingNumber;
              }
            } else {
              if (integration) {
                this.cargoErrorHandler(
                  result.data,
                  this.cargoDetail.cargo?.cargo_company
                );
              }
            }
          },
          onError: () => {
            this.$toast.error("Sunucu Hatası");
          },
          onFinish: () => {
            if (!integration) {
              this.closeCargoModal();
            } else {
              this.barcodeSubmit = false;
            }
            this.$refs.orderDetailRef.onReturnSubmit("get-tracking-number");
          },
        });
      }
    },
    cargoErrorHandler(result, company) {
      let message = "Kargo Kaydı Yapılamadı";
      if (company) {
        const companyResponse = `${company.code}_response`;
        if (result.hasOwnProperty(companyResponse)) {
          const companyResult = result[companyResponse];
          switch (company.code) {
            case "yurtici":
              message =
                companyResult?.ShippingOrderResultVO.shippingOrderDetailVO
                  .errMessage ||
                companyResult?.ShippingOrderResultVO.outResult ||
                message;
              break;
            case "paketlegitsin":
              message =
                companyResult?.aciklama || companyResult?.error || message;
              break;
            case "aras":
              message =
                companyResult?.soapBody?.SetOrderResponse?.SetOrderResult
                  ?.OrderResultInfo?.ResultMessage ?? companyResult;
              break;
            case "mng":
              message = companyResult?.SiparisGirisiDetayliV3Result;
              break;
            case "hepsijet":
              message = companyResult;
              break;
          }
        }
      }

      this.$swal({
        title: "Hatalı İşlem",
        html: `<div class="mt-3 card alert-danger">
                <div class="card-body">${message}</div>
              </div>`,
        icon: "error",
        confirmButtonText: "Tamam",
      });
    },
    createBarcodeUrl(url) {
      let newUrl = url;

      if (url && helper.isURL(url)) {
        newUrl = new URL(url);
        const token = this.session.accessToken;

        if (token) {
          // newUrl = newUrl.split("?")[0];
          newUrl += `?domain=${window.location.host}&token=${token}`;
        }
      }

      return newUrl;
    },
    updateCargoInfo(data) {
      if (data.id) {
        if (data.cargoDetail.cargo_tracking_number) {
          const formData = {
            cargo_tracking_number: data.cargoDetail.cargo_tracking_number,
            cargo_id: data.cargo.id,
          };

          this.updateCargoInformation({
            id: data.id,
            form: formData,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Kargo Bilgisi Güncellendi");
              } else {
                this.$toast.error("Kargo Bilgisi Güncellenemedi");
              }
            },
          });
        } else {
          this.$swal({
            title: "Hatalı İşlem",
            text: "Kargo bilgisini güncellemek için takip numarası girmelisiniz",
            icon: "warning",
          });
        }
      }
    },
    saveBarcode() {
      this.barcodeSubmit = true;
      this.changeOrderStatus(this.cargoDetail.data);
      this.updateCargoBarcodeNumber();
    },
    onSendERP(id, item) {
      if (this.erpConfig && id) {
        this.sendERP({
          order_id: id,
          onSuccess: (result) => {
            if (result && result.data) {
              // Row
              if (item) {
                if (result.data.status) {
                  item.erp_status = true;
                  item.erp_message = result.data.message;
                } else {
                  item.erp_status = false;
                  item.erp_message = result.data.message;
                }
              }

              result.data.status = !result.data.status ? "error" : "success";
              result.data.message =
                result.data.message && result.data.message.length
                  ? result.data.message
                  : "ERP Gönderilemedi";

              this.showAlert(result);
            }
          },
          onError: () => {
            this.$toast.error("ERP Gönderilemedi");
            item.erp_status = false;
            item.erp_message = "ERP Gönderilemedi";
          },
        });
      }
    },
    onSendInvoice(id, item) {
      if (this.isInvoiceActive && id) {
        this.sendInvoice({
          order_id: id,
          onSuccess: (result) => {
            if (result && result.data) {
              // Row
              if (item) {
                if (result.data.status) {
                  item.invoice_status = true;
                  item.invoice_message = "Fatura Oluşturuldu";
                } else {
                  item.invoice_status = false;
                  item.invoice_message = result.data.message;
                }
              }

              result.data.status = !result.data.status ? "error" : "success";
              result.data.message =
                result.data.message && result.data.message.length
                  ? result.data.message
                  : "Fatura Oluşturulamadı";

              this.showAlert(result);
            }
          },
          onError: () => {
            this.$toast.error("Fatura Oluşturulamadı");
            item.invoice_status = false;
            item.invoice_message = "Fatura Oluşturulamadı";
          },
        });
      }
    },
    onPrintInvoice(id, item) {
      if (this.isInvoiceActive && id) {
        this.printInvoice({
          order_id: id,
          fileName: `${item.order_no}_Satış Faturası`,
          onSuccess: (result) => {
            item.invoice_status = true;
          },
        });
      }
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    onChangeOrderFilter(value) {
      this.orderFilter.value = value;
      localStorage.setItem("order_status_filter", JSON.stringify(value));
      this.updateOrderList();
    },
    setOrderStatusFilter() {
      let result = {
        id: "all",
        name: "Tüm Siparişler",
      };

      if (localStorage.hasOwnProperty("order_status_filter")) {
        const storedValue = localStorage.getItem("order_status_filter");
        result = JSON.parse(storedValue);
      }
      this.orderFilter.value = result;
      localStorage.setItem("order_status_filter", JSON.stringify(result));

      /* Filtrelerde bulunanların düzgün yüklenmesi için devre dışı bırakıldı.*/
      //this.onChangeOrderFilter(result);
    },
  },
  computed: {
    ...mapState({
      order: (state) => state.order,
      shared: (state) => state.shared,
      cargo: (state) => state.shared.cargoCompanies,
      memberGroups: (state) => state.membergroup.list,
      session: (state) => state.session,
      config: (state) => state.session.config,
      marketplace: (state) => state.marketplace,
      cityList: (state) => state.address.cities,
      orderDetailModal: (state) => state.orderDetailModal,
    }),
    exportSelection() {
      let result = helper.clone(this.fixedKeys);
      let storedKeys = localStorage.getItem("order_excel_keys");
      if (storedKeys) {
        storedKeys = JSON.parse(storedKeys);
        storedKeys.forEach((key) => {
          if (!result[key]) {
            result.push(key);
          }
        });
      }

      return result;
    },
    getCargoCompanyNames() {
      let result = [];

      this.cargo.firmList.forEach((cargoFirm) => {
        const cargoData = {
          id: cargoFirm?.id,
          name: cargoFirm?.cargo_company?.name,
          cargo_company: cargoFirm.cargo_company,
          is_active: cargoFirm.is_active,
          is_insurance: cargoFirm.is_insurance,
          is_integration: cargoFirm.is_integration,
          selectable:
            this.orderData &&
            this.orderData.available_cargo_ids &&
            this.orderData.available_cargo_ids.includes(cargoFirm.id),
        };

        if (cargoData && cargoData.id && cargoData.name) {
          result.push(cargoData);
        }
      });

      return result;
    },
    siteUrl() {
      return this.config["site.url"] || null;
    },
    siteLogo() {
      return this.config["site.logo"] || null;
    },
    getColumns() {
      this.columns = this.columns.filter((item) => item.name !== "status");

      const orderTypes = this.order.orderTypes;
      const data = {
        name: "status",
        th: "Durum",
        type: "select",
        options: orderTypes,
        width: "10%",
      };

      this.columns.splice(7, 0, data);

      if (
        this.isInvoiceActive &&
        !this.columns.filter((c) => c.name == "invoice_number").length
      ) {
        this.columns.push({
          name: "invoice_number",
          th: "Fatura No",
          width: "10%",
        });
      }

      return this.columns;
    },
    orderList() {
      return this.order.list.map((row) => {
        let buttons = [];

        // ERP Status
        if (this.erpConfig) {
          let erpLoading = {
            icon: "fas fa-spinner fa-spin",
            class: "btn-sm btn-outline-primary ml-2",
            action: "sending-erp",
            tooltip: "ERP Gönderiliyor",
          };

          let erpButton = {
            icon: "fas fa-paper-plane",
            class: "btn-sm btn-outline-primary ml-2",
            action: "send-erp",
            tooltip: "ERP Gönder",
          };

          if (row.erp_status !== "-1") {
            if (row.erp_status) {
              erpButton = {
                icon: "fas fa-check",
                class: "btn-sm btn-outline-success cursor-default ml-2",
                action: "erp-sent",
                tooltip: row.erp_message,
              };
            } else if (row.erp_message) {
              erpButton = {
                icon: "fas fa-paper-plane",
                class: "btn-sm btn-outline-danger ml-2",
                action: "send-erp",
                tooltip: row.erp_message + "<br /> Tekrar Gönder",
              };
            }
            buttons = [erpButton];
          } else {
            buttons = [erpLoading];
          }
        }

        if (this.isInvoiceActive) {
          // Fatura Oluşturuluyor
          let invoiceLoading = {
            icon: "fas fa-spinner fa-spin",
            class: "btn-sm btn-outline-primary ml-2",
            action: "sending-invoice",
            tooltip: "Fatura Oluşturuluyor",
          };

          // Fatura Oluştur
          let invoiceButton = {
            icon: "fas fa-receipt",
            class: "btn-sm btn-outline-primary ml-2",
            action: "send-invoice",
            tooltip: "Fatura Oluştur",
          };

          if (row.invoice_status !== "-1") {
            if (row.invoice_status && row.invoice_url) {
              invoiceButton = {
                icon: "fas fa-file-invoice",
                class: "btn-sm btn-outline-secondary ml-2",
                action: "print-invoice",
                tooltip: "Fatura Yazdır",
              };

              // Yazdır
              // buttons.push({
              //   icon: "fas fa-receipt",
              //   class: "btn-sm btn-outline-secondary cursor-default ml-2",
              //   action: "invoice-sent",
              // })
            } else if (row.invoice_message) {
              invoiceButton = {
                icon: "fas fa-receipt",
                class: "btn-sm btn-outline-danger ml-2",
                action: "send-invoice",
                tooltip: row.invoice_message + "<br /> Tekrar Dene",
              };
            }
            buttons = [...buttons, invoiceButton];
          } else {
            buttons = [...buttons, invoiceLoading];
          }
        }
        const statusName =
          row.status && row.status.name ? row.status.name : row.status;
        switch (statusName) {
          case "Ödeme Bekleniyor":
          case "İptal Edildi":
          case "İade Edildi":
          case "Kısmi İade Edildi":
            buttons = [];
            break;
        }

        row.buttons = buttons;
        return row;
      });
    },
    erpConfig() {
      return (
        this.config["site.erp_integration"] == "1" ||
        this.config["site.erp_integration"] == 1
      );
    },
    marketplaceConfig() {
      return (
        this.config["site.marketplace_integration"] == "1" ||
        this.config["site.marketplace_integration"] == 1
      );
    },
    isInvoiceActive() {
      return (
        this.config["site.invoice_integration"] == "1" ||
        this.config["site.invoice_integration"] == 1
      );
    },
    getExportables() {
      let result = ["excel"];

      if (this.selectedOrders && this.selectedOrders.length) {
        result = [...result, "bulk-print"];
      }

      return result;
    },
    detailMarketplaceList() {
      let marketplaceList = this.marketplace.list;
      let eCommerce = {
        id: "e-commerce",
        title: "E-Ticaret",
      };

      const result = [eCommerce, ...marketplaceList];
      return result;
    },
    getCargoCompanyCode() {
      let result = this.order.cargo_company_code;

      if (
        !result &&
        this.orderData.cargoDetail &&
        this.orderData.cargoDetail.cargo &&
        this.orderData.cargoDetail.cargo.cargo_company
      ) {
        result = this.orderData.cargoDetail.cargo.cargo_company.code;
      }

      return result;
    },
  },
  mounted() {
    if (this.marketplaceConfig) {
      this.$store.dispatch("marketplace/getList");
    }
    this.getCargoFirm();
    this.getPaymentTypes();
    this.getOrderTypes();
    this.getCities({
      id: "G85jd70Qz",
    });
    this.setOrderStatusFilter();
  },
  watch: {
    printCounter: function (currentValue) {
      if (currentValue <= 0) {
        this.$refs.bulkPrint.$refs.bulkPrintButton.click();
        this.printBtn.loading = false;
        this.printBtn.title = "Seçilenleri Yazdır";
      }
    },
  },
};
</script>